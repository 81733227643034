import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';

class NotFoundPage extends React.Component {
  render(props) {
    return (
      <Layout>
        <Helmet>
          <title>Page Not Found | High Park Academy</title>
          <meta name="description" content="Page Not Found"></meta>
        </Helmet>
        <div className="page-404">
          <h1 className="text-center">Page Not Found</h1>
          <p>You just hit a page that doesn&#39;t exist...</p>
          <picture>
            <source
              srcSet="/images/illustrations/plant.webp"
              type="image/webp"
            />
            <source srcSet="/images/illustrations/plant.png" type="image/png" />
            <img src="/images/illustrations/plant.png" alt="page not found" />
          </picture>
          <Link to="/">Back to Home</Link>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
